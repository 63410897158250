import React, { useState } from "react";
import styles from "./ContactSection.module.css";
import Input from "../../ui/Input/Input";
import Textarea from "../../ui/Textarea/Textarea";
import Button from "../../ui/Button/Button";

const ContactSection: React.FC = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Submitted Form!");
    }
    return (
        <section className={styles.section}>
            <h2>Contact</h2>
            <div className={styles.wrapper}>
                <div>
                    <form action="submit" onSubmit={handleSubmit} className={styles.form}>
                        <Input
                            variant="material"
                            type="text"
                            placeholder="Name"
                            materialLabelBackground="var(--bg-main)"
                            outlineColor="var(--neutral-200)"
                            textColor="var(--text-main)"
                            outlineColorActive="#ff70c6"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            style={{width: "100%"}}
                        />
                        <Input
                            variant="material"
                            type="email"
                            placeholder="Email"
                            materialLabelBackground="var(--bg-main)"
                            outlineColor="var(--neutral-200)"
                            textColor="var(--text-main)"
                            outlineColorActive="#ff70c6"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            style={{width: "100%"}}
                        />
                        <Textarea 
                            variant="material"
                            placeholder="Message"
                            materialLabelBackground="var(--bg-main)"
                            outlineColor="var(--neutral-200)"
                            textColor="var(--text-main)"
                            outlineColorActive="#ff70c6"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                        <Button style={{fontWeight: "normal", justifyContent: "center"}}>Send Message</Button>
                    </form>
                </div>
                <div className={styles.right}>
                    <a href="mailto:jordan@connxting.com&subject=Portfolio%20Inquiry">jordan@connxting.com</a>
                    <a href="tel:1-520-240-9942">+1 (520) 240-9942</a>
                    <a href="https://www.github.com/jordankmars" target="_blank" rel="noreferrer">Github</a>
                    <a href="https://www.linkedin.com/in/jordanmars" target="_blank" rel="noreferrer">LinkedIn</a>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;