import React, { useState } from "react";
import styles from './Input.module.css';

type VisibilityProps = {
    color?: string;
    size?: string;
};

const VisibilityIcon: React.FC<VisibilityProps> = ({
    color = '#000000',
    size = '20px'
}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={color}><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Z"/></svg>
};

const NoVisibilityIcon: React.FC<VisibilityProps> = ({
    color = '#000000',
    size = '20px'
}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={color}><path d="M764-84 624-222q-35 11-71 16.5t-73 5.5q-134 0-245-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q22-39 47-76t58-66l-83-84q-11-11-11-27.5T84-820q11-11 28-11t28 11l680 680q11 11 11.5 27.5T820-84q-11 11-28 11t-28-11ZM480-320q11 0 21-1t20-4L305-541q-3 10-4 20t-1 21q0 75 52.5 127.5T480-320Zm0-480q134 0 245.5 72.5T900-537q5 8 7.5 17.5T910-500q0 10-2 19.5t-7 17.5q-19 37-42.5 70T806-331q-14 14-33 13t-33-15l-80-80q-7-7-9-16.5t1-19.5q4-13 6-25t2-26q0-75-52.5-127.5T480-680q-14 0-26 2t-25 6q-10 3-20 1t-17-9l-33-33q-19-19-12.5-44t31.5-32q25-5 50.5-8t51.5-3Zm79 226q11 13 18.5 28.5T587-513q1 8-6 11t-13-3l-82-82q-6-6-2.5-13t11.5-7q19 2 35 10.5t29 22.5Z"/></svg>
};

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    variant?: 'normal' | 'material';
    borderRadius?: 'rounded' | 'soft' | 'hard';
    materialLabelBackground?: string;
    outlineColor?: string;
    outlineColorActive?: string;
    backgroundColor?: string;
    textColor?: string;
    iconColor?: string;
};

const Input: React.FC<InputProps> = ({
    type = 'text',
    placeholder,
    style,
    variant = 'normal',
    borderRadius = 'soft',
    materialLabelBackground = '#ffffff',
    outlineColor = '#000000',
    outlineColorActive = '#000000',
    backgroundColor = 'transparent',
    textColor = '#000000',
    iconColor,
    ...props
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const isPasswordType = type === 'password';
    const inputType = isPasswordType && isPasswordVisible ? 'text' : 'password';

    const isMaterial = variant === 'material';

    const inlineStyles = {
        '--material-label-background': materialLabelBackground,
        '--outline-color': outlineColor,
        '--outline-color-active': outlineColorActive,
        '--background-color': backgroundColor,
        '--text-color': textColor
    } as React.CSSProperties;

    return (
        <div
            className={styles.inputWrapper}
            style={inlineStyles}
        >
            <input 
                className={`${isMaterial ? styles.inputField : styles.input} ${styles[borderRadius]}`}
                type={isPasswordType ? inputType : type}
                placeholder={isMaterial ? "" : placeholder}
                style={isPasswordType ? {paddingRight: "2rem", ...style} : {...style}}
                {...props}
            />
            {isMaterial && (
                <label 
                    htmlFor={props.id}
                    className={styles.placeholderText}
                    id={`placeholder-${props.id}`}
                >
                    <div
                        className={styles.labelText}
                    >
                        {placeholder}
                    </div>
                </label>
            )}
            {isPasswordType && (
                <span
                    className={styles.toggleVisibility}
                    onClick={togglePasswordVisibility}
                    role="button"
                    aria-label={isPasswordVisible ? "Hide password" : "Show password"}
                >
                    {isPasswordVisible ? <NoVisibilityIcon color={iconColor || textColor}/> : <VisibilityIcon color={iconColor || textColor}/>}
                </span>
            )}
        </div>
    );
};

export default Input;