import React from "react";
import styles from "./Footer.module.css";

import Button from "../Button/Button";

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerLeft}>
                <p>&copy; {new Date().getFullYear()} Jordan Mars. All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default Footer;