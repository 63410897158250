import React from "react";
import styles from './AboutSection.module.css';

const AboutSection: React.FC = () => {
    return (
        <section className={styles.section}>
            <h2>Me and My Values</h2>
            <div className={styles.grid}>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 4",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">equal</span>
                        </div>
                        <h3>Equity</h3>
                    </div>
                    <p>Equity is central to my professional and personal life. I believe all efforts should made to level the playing field, which is especially important in engineering as women are not even close to being proportioanlly represented, not only in leadership, but the field as a whole.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 4",
                    gridRow: "span 3",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">accessibility</span>
                        </div>
                        <h3>Accessibility</h3>
                    </div>
                    <p>Accessibility has been at my core for my entire life. My mother has dedicated her life to assisting people with disabilities through assimilation, assistance, and compassion and has raised me with those values throughout me. I apply this to engineering through striving to make my projects accessible and push web accessibility forward with my open source work.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 3",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">handshake</span>
                        </div>
                        <h3>Collaboration</h3>
                    </div>
                    <p>The ability to collaborate with a team to reach a common goal is not only beneficial personally, but for the greater culture and community. No matter at work, or in my personal live I love to collaborate to further inclusion and success.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 1",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">globe</span>
                        </div>
                        <h3>Where</h3>
                    </div>
                    <p>Born and raised in Tucson, Arizona, actively looking for opportunities in Boston, Massachusetts and remote.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 2",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">sentiment_very_satisfied</span>
                        </div>
                        <h3>Passion</h3>
                    </div>
                    <p>My passion for my work extends beyond programming. Creating great things and driving innovation motivates me beyond the traditional work and allows me to strive for success.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 2",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">school</span>
                        </div>
                        <h3>Educating</h3>
                    </div>
                    <p>I fostered a love for teaching and mentoring throughout my education and experience and it is my hope to one day be able to teach the subject which I have grown to love.</p>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;