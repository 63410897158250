import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import MainPage from './pages/MainPage/MainPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/"
          element={<MainPage/>}
        />
        <Route 
          path='*'
          element={<Navigate to={"/"}/>}
        />
      </Routes>
    </BrowserRouter>
    );
}

export default App;
