import React from 'react';
import { motion } from "framer-motion";
import styles from './TitleSection.module.css';

import TextHighlight from '../../ui/TextHighlight/TextHighlight';
import DotBackground from '../../ui/DotBackground/DotBackground';
import Button from '../../ui/Button/Button';

const TitleSection: React.FC = () => {
    const handleDownload = () => {
        // Specify the path to your PDF file
        const filePath = '/files/resume.pdf';
        
        // Create a link element and set attributes
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Jordan Mars - Resume.pdf'; // The name of the file when downloaded
        
        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div className={styles.section}>
            <DotBackground>
                <div className={styles.container}>
                    <h1>
                    <motion.span
                        initial={{
                            rotate: 0,
                        }}
                        animate={{
                            rotate: [0, 5, -5, 5, -2.5, 2.5, 0], // Keyframes for waving effect
                        }}
                        transition={{
                            duration: 1.5,
                            repeat: Infinity,
                            repeatType: "loop",
                            ease: "easeInOut",
                        }}
                        style={{ display: "inline-block" }}
                    >
                        👋
                    </motion.span>
                        &nbsp;Hi, I'm <TextHighlight>Jordan Mars</TextHighlight>
                    </h1>
                    <h2 style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>Software Engineer
                        <em style={{
                            fontSize: ".85rem",
                            padding: ".25rem",
                            borderRadius: ".5rem",
                            background: "linear-gradient(to right, var(--text-main), var(--text-secondary)",
                            color: "var(--bg-main)"
                        }}>
                            He/Him
                        </em>
                    </h2>
                    <p>Full-stack Engineer with a focus on visual design and user interactions.</p>
                    <p>📍 Tucson, AZ || Boston, MA</p>
                    <div className={styles.horizontalContainer}>
                        <Button
                            as='a'
                            variant='ghost'
                            size='small'
                            textColor='var(--text-secondary)'
                            hoverColor='var(--bg-secondary)'
                            hoverTextColor='var(--text-main)'
                            activeColor='#404040'
                            activeTextColor='var(--text-main)'
                            href='https://github.com/jordankmars'
                            target='_blank'
                        >
                            GitHub
                        </Button>
                        <Button
                            as='a'
                            variant='ghost'
                            size='small'
                            textColor='var(--text-secondary)'
                            hoverColor='var(--bg-secondary)'
                            hoverTextColor='var(--text-main)'
                            activeColor='#404040'
                            activeTextColor='var(--text-main)'
                            href='https://linkedin.com/in/jordanmars'
                            target='_blank'
                        >
                            LinkedIn
                        </Button>
                        <Button
                            as='a'
                            variant='ghost'
                            size='small'
                            textColor='var(--text-secondary)'
                            hoverColor='var(--bg-secondary)'
                            hoverTextColor='var(--text-main)'
                            activeColor='#404040'
                            activeTextColor='var(--text-main)'
                            href='mailto:jordan@connxting.com&subject=Portfolio%20Inquiry'
                        >
                            Email
                        </Button>
                        <Button
                            variant='ghost'
                            size='small'
                            textColor='var(--text-secondary)'
                            hoverColor='var(--bg-secondary)'
                            hoverTextColor='var(--text-main)'
                            activeColor='#404040'
                            activeTextColor='var(--text-main)'
                            onClick={handleDownload}
                        >
                            <span className='material-symbols-rounded' style={{fontSize: "18px"}}>
                                download
                            </span>
                            Resume
                        </Button>
                    </div>
                </div>
            </DotBackground>
        </div>
    );
};

export default TitleSection;