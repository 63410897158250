import React from "react";
import styles from "./SkillsSection.module.css";
import { SpotlightCardGroup } from "../../ui/SpotlightCard/SpotlightCard";

import { 
    JavaScript, 
    TypeScript, 
    Python, 
    Cpp,
    Java,
    ReactLogo,
    Express,
    MongoDB,
    PostgreSQL,
    NextJS,
    Django,
    FramerMotion,
    Firebase,
    Swift
} from "./Logos";

const cardsData = [
    { logo: <JavaScript/>, text: "JavaScript", borderColor: "#F0DB4F75", spotlightColor: "#F0DB4F50", logoColor: "#F0DB4F" },
    { logo: <TypeScript/>, text: "TypeScript", borderColor: "#3178C675", spotlightColor: "#3178C650", logoColor: "#3178C6" },
    { logo: <Python/>, text: "Python", borderColor: "#ffde5775", spotlightColor: "#ffde5750", logoColor: "#ffde57" },
    { logo: <Cpp/>, text: "C++", borderColor: "#5599ff75", spotlightColor: "#5599ff50", logoColor: "#5599ff" },
    { logo: <Java/>, text: "Java", borderColor: "#5382a175", spotlightColor: "#5382a150", logoColor: "#5382a1" },
    { logo: <ReactLogo/>, text: "React", borderColor: "#61DBFB75", spotlightColor: "#61DBFB50", logoColor: "#61DBFB" },
    { logo: <Express/>, text: "Express.js", borderColor: "var(--text-75)", spotlightColor: "var(--text-50)", logoColor: "var(--text-main)" },
    { logo: <MongoDB/>, text: "MongoDB", borderColor: "#00684a75", spotlightColor: "#00684a50", logoColor: "#00684a" },
    { logo: <NextJS/>, text: "Next.js", borderColor: "var(--text-75)", spotlightColor: "var(--text-50)", logoColor: "var(--text-main)" },
    { logo: <PostgreSQL/>, text: "PostgreSQL", borderColor: "#33679175", spotlightColor: "#33679150", logoColor: "#336791" },
    { logo: <Django/>, text: "Django", borderColor: "#00684a75", spotlightColor: "#00684a50", logoColor: "#00684a" },
    { logo: <FramerMotion/>, text: "Framer Motion", borderColor: "#F700A275", spotlightColor: "#F700A250", logoColor: "on" },
    { logo: <Firebase/>, text: "Firebase", borderColor: "#f6820d75", spotlightColor: "#f6820d50", logoColor: "#f6820d" },
    { logo: <ReactLogo/>, text: "React Native", borderColor: "#61DBFB75", spotlightColor: "#61DBFB50", logoColor: "#61DBFB" },
    { logo: <Swift/>, text: "Swift", borderColor: "#F0513875", spotlightColor: "#F0513850", logoColor: "#F05138" },
]

const SkillsSection: React.FC = () => {
    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <h2 style={{zIndex: "1"}}>Technologies</h2>
                <SpotlightCardGroup 
                    cardData={cardsData}
                />
                <p>& more...</p>
            </div>
        </div>
    );
};

export default SkillsSection;