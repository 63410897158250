import React from "react";
import styles from "./DotBackground.module.css";

type DotBackgroundProps = React.HTMLAttributes<HTMLDivElement>;

const DotBackground: React.FC<DotBackgroundProps> =({
    children
}) => {
    return (
        <div 
            className={styles.container}
        >
            <div 
                className={styles.gradient}
            >
                {children}
            </div>
        </div>
    );
};

export default DotBackground;