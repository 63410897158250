import React from "react";
import styles from './Button.module.css';
import { Link, LinkProps } from "react-router-dom";

type ButtonProps = {
    as?: 'button' | 'a' | 'link';
    size?: 'small' | 'medium' | 'large';
    borderRadius?: 'rounded' | 'soft' | 'hard';
    variant?: 'border' | 'background' | 'ghost';
    color?: string;
    textColor?: string;
    hoverColor?: string;
    hoverTextColor?: string;
    activeColor?: string;
    activeTextColor?: string;
} & (
    | (React.ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' })
    | (React.AnchorHTMLAttributes<HTMLAnchorElement> & { as: 'a' })
    | (LinkProps & { as: 'link'; to: string })
);

const Button: React.FC<ButtonProps> =({
    as = 'button',
    children,
    style,
    size = 'medium',
    borderRadius = 'soft',
    variant = 'background',
    color = '#e0e0e0',
    textColor = 'black',
    hoverColor = '#d0d0d0',
    hoverTextColor = 'black',
    activeColor = '#dddddd',
    activeTextColor = 'black',
    ...props
}) => {
    const inlineStyles = {
        '--color': color,
        '--text-color': textColor,
        '--hover-color': hoverColor,
        '--hover-text-color': hoverTextColor,
        '--active-color': activeColor,
        '--active-text-color': activeTextColor
    } as React.CSSProperties;

    if (as === 'a') {
        return (
            <a
                className={`${styles.button} ${styles[size]} ${styles[borderRadius]} ${styles[variant]}`}
                style={{...inlineStyles, ...style}}
                {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
            >
                {children}
            </a>
        )
    } else if (as === 'link') {
        return (
            <Link
                className={`${styles.button} ${styles[size]} ${styles[borderRadius]} ${styles[variant]}`}
                style={{...inlineStyles, ...style}}
                {...(props as LinkProps)}
            >
                {children}
            </Link>
        )
    } else {
        return (
            <button
                className={`${styles.button} ${styles[size]} ${styles[borderRadius]} ${styles[variant]}`}
                style={{...inlineStyles, ...style}}
                {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
            >
                {children}
            </button>
        );
    }
};

export default Button;