import React, { useState } from "react";
import styles from "./EducationExperienceSection.module.css";
import { motion } from "framer-motion";
import { experience, education } from "../../../constants";

const EducationExperienceSection: React.FC = () => {
    const [activePage, setActivePage] = useState<"experience" | "education">("experience");
    return (
        <section className={styles.section}>
            <div className={styles.header}>
                <h2>{activePage === 'education' ? "Education" : "Experience"}</h2>
                <div className={styles.toggleWrapper}>
                    <button 
                        onClick={() => setActivePage("experience")}
                        className={`${styles.toggleButton} ${activePage === 'experience' ? styles.active : ""}`}
                    >Experience</button>
                    <button 
                        onClick={() => setActivePage("education")}
                        className={`${styles.toggleButton} ${activePage === 'education' ? styles.active : ""}`}
                    >Education</button>
                </div>
                <h2 style={{color: "var(--bg-main)", pointerEvents: "none", userSelect: "none"}}>{activePage === 'education' ? "Education" : "Experience"}</h2>
            </div>
            {activePage === "experience" ? (
                <div className={styles.wrapper}>                
                    <div className={styles.card}>
                        {experience.map((e) => (
                            <div key={e.id} className={styles.experience}>
                                <div className={styles.top}>
                                    <div className={styles.logo}>
                                        <img src={e.logo} alt="" />
                                    </div>
                                    <div className={styles.mainText}>
                                        <strong>{e.company}</strong>
                                        <p className={styles.weakText}>{e.title} &mdash; {e.duration}</p>
                                    </div>
                                </div>
                                <div className={styles.bottom}>
                                    <div className={styles.verticalLine} style={e.id === experience.length ? {background: "transparent"} : {} } />
                                    <div className={styles.paragraph}>
                                        <p>{e.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className={styles.wrapper}>                
                    <div className={styles.card}>
                        {education.map((e) => (
                            <div key={e.id} className={styles.experience}>
                                <div className={styles.top}>
                                    <div className={styles.logo}>
                                        <img src={e.logo} alt="" />
                                    </div>
                                    <div className={styles.mainText}>
                                        <strong>{e.school}</strong>
                                        <p className={styles.weakText}>{e.degree} &mdash; {e.grad}</p>
                                    </div>
                                </div>
                                <div className={styles.bottom}>
                                    <div className={styles.verticalLine} style={e.id === education.length ? {background: "transparent"} : {} } />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
};

export default EducationExperienceSection;