import React, { useState } from "react";
import styles from "./NewNavbar.module.css";
import { useTheme } from '../../../context/ThemeContext';
import Button from "../Button/Button";

type NavbarProps = {
    activePage?: string;
}

const NewNavbar: React.FC<NavbarProps> = ({ activePage }) => {
    const { theme, toggleTheme } = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const toggleNav = () => setMobileOpen(prev => !prev);

    return (
        <>
        <nav className={styles.nav}>
            <div className={styles.logo}>
                <h3 style={{fontFamily: "monospace"}}>&lt;Jordan Mars /&gt;</h3>
            </div>
            <div>
                <a href="#about">About</a>
            </div>
            <div>
                <a href="#experience">Experience</a>
            </div>
            <div>
                <a href="#projects">Projects</a>
            </div>
            <div>
                <a href="#professional">Professional Persona</a>
            </div>
            <div>
                <a href="#contact">Contact</a>
            </div>
            <Button 
                variant="ghost"
                textColor="var(--text-main)"
                hoverColor="var(--neutral-200)"
                hoverTextColor="var(--text-main)"
                activeColor="var(--neutral-300)"
                activeTextColor="var(--text-main)"
                onClick={toggleTheme}
                style={{
                    padding: ".25rem"
                }}
            >
                <span className="material-symbols-rounded" style={{
                    fontSize: "18px"
                }}>{theme === "dark" ? "light_mode" : "dark_mode"}</span>
            </Button>
        </nav>
        <nav className={styles.navbarMobile}>
            <div className={styles.navContent}>
                <div className={styles.navLeft}>
                    <h3 style={{fontFamily: "monospace"}}>&lt;Jordan Mars /&gt;</h3>
                </div>
                <div className={styles.navRight}>
                    <Button 
                        variant="ghost"
                        textColor="var(--text-main)"
                        hoverColor="var(--neutral-200)"
                        hoverTextColor="var(--text-main)"
                        activeColor="var(--neutral-300)"
                        activeTextColor="var(--text-main)"
                        onClick={toggleTheme}
                        style={{
                            padding: ".25rem",
                            width: "fit-content"
                        }}
                    >
                        <span className="material-symbols-rounded" style={{
                            fontSize: "18px"
                        }}>{theme === "dark" ? "light_mode" : "dark_mode"}</span>
                    </Button>
                    <button className={styles.mobileNavButton} onClick={toggleNav}>
                        <span className="material-symbols-rounded">{mobileOpen ? "close" : "menu"}</span>
                    </button>
                </div>
            </div>
            {mobileOpen && (
                <div className={styles.mobileMenu}>
                    <div>
                        <a href="#about">About</a>
                    </div>
                    <div>
                        <a href="#experience">Experience</a>
                    </div>
                    <div>
                        <a href="#projects">Projects</a>
                    </div>
                    <div>
                        <a href="#professional">Professional Persona</a>
                    </div>
                    <div>
                        <a href="#contact">Contact</a>
                    </div>
                </div>
            )}
        </nav>
        </>
    );
};

export default NewNavbar;