import React from "react";
import { motion } from "framer-motion";
import styles from "./TextHighlight.module.css";

type TextHighlightProps = React.HTMLAttributes<HTMLDivElement>;

const TextHighlight: React.FC<TextHighlightProps> = ({ children }) => {
  return (
    <span className={styles.textWrapper}>
      {/* Existing background highlight animation */}
      <motion.span
        className={styles.text}
        initial={{ backgroundSize: "0% 100%" }}
        animate={{ backgroundSize: "100% 100%" }}
        transition={{ duration: 1, ease: "linear", delay: 0.25 }}
      >
        {children}
      </motion.span>
      {/* Existing background highlight animation */}
      <motion.span
        className={styles.highlight}
        initial={{ backgroundSize: "0% 100%" }}
        animate={{ backgroundSize: "100% 100%" }}
        transition={{ duration: 1, ease: "linear", delay: 0.25 }}
      >
        {children}
      </motion.span>
    </span>
  );
};

export default TextHighlight;