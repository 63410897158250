export const experience = [
  {
    id: 1,
    company: "Connxting",
    logo: "/assets/logos/connxting.png",
    title: "CEO and Founder",
    duration: "February 2022 - Present",
    desc: "Connxting is a business technology company currently providing marketing services with proprietary analysis and optimization machine learning algorithms. We are also currently developing a suite of SaaS applications to make it easy and cost effective for businesses to operate in the modern world.",
  },
  {
    id: 2,
    company: "Connxting Labs",
    logo: "/assets/logos/connxtingLabs.png",
    title: "CTO and Founder",
    duration: "June 2024 - Present",
    desc: "Connxting Labs is an open source development startup creating building blocks for the future of the web. Our current projects include Connxt, an in-development, modular web framework, ConnxtUI, a modern, modular, and customizable component/UI library, ConnxtScaffold, a CLI for quickly scaffolding a variety of different projects and applications, and more.",
  },
  {
    id: 3,
    company: "United Cerebral Palsy of Southern Arizona",
    logo: "/assets/logos/ucpsa.png",
    title: "Software Engineer and IT Specialist",
    duration: "January 2022 - Present",
    desc: "Developed a local SQL database to store and read historical data, and migrated the historical data from our previous provider. Developed internal applications including a report generator to analyze form data and generate a report, saving time for program managers managing 1,000+ clients, and a web portal to collect and manage data from the different programs we run.",
  },
]

export const education = [
  {
    id: 1,
    school: "The University of Arizona",
    logo: "/assets/logos/arizona.png",
    grad: "2026",
    degree: "Bachelor of Science (B.S.) - Management Information Systems",
  },
  {
    id: 2,
    school: "The University of Arizona",
    logo: "/assets/logos/arizona.png",
    grad: "2026",
    degree: "Bachelor of Science (B.S.) - Entrepreneurship",
  },
  {
    id: 3,
    school: "The University of Arizona",
    logo: "/assets/logos/arizona.png",
    grad: "2027",
    degree: "Master of Science (M.S.) - Management Information Systems",
  },
]

export const myProjects = [
  {
    title: 'Connxting Accounts System',
    emoji: '🔐',
    desc: 'Connxting Platforms is a revolutionary suite of apps for business by my company Connxting. The account system in particular is robust both in terms of security and functionality.',
    subdesc: 'Built an invitation system allowing users to join many companies, and each company having many users. Implemented OAuth, 2FA, and other authentication protocols without the use of any libraries for maximum customization, security, and expansive future use cases.',
    tags: [
      {
        id: 1,
        color: 'var(--red-800)',
        text: "In Development"
      },
      {
        id: 2,
        color: 'var(--grey-700)',
        text: "Closed Source"
      }
    ],
    preview: "/assets/connxtingAccounts.png"
  },
  {
    title: 'Connxting Marketing',
    emoji: '📢',
    desc: 'Connxting Marketing is the first app within Connxting Platforms and helps increase results of marketing campaigns across all their digital channels using advanced, bespoke machine learning algorithms.',
    subdesc: `Built several machine learning algorithms that analyze data and optimize marketing campaigns curated from all of the user's digital marketing channels. Built an easy to use interface for managing campaigns, content, social media posts, analytics, and more.`,
    tags: [
      {
        id: 1,
        color: 'var(--red-800)',
        text: "In Development"
      },
      {
        id: 2,
        color: 'var(--grey-700)',
        text: "Closed Source"
      }
    ],
    preview: "/assets/connxtingMarketing.png"
  },
  {
    title: 'ConnxtUI',
    emoji: '📺',
    desc: 'ConnxtUI is a component/ui library that is modern, customizable, and modular. Beyond being built to be completely customizable, it is installed directly into the project, rather than as a dependency.',
    subdesc: `Built common UI/UX components with complete customization as well as a CLI that initializes ConnxtUI and specific settings as well as adds components directly into the project based on the initialized configuration settings.`,
    tags: [
      {
        id: 1,
        color: 'var(--green-700)',
        text: "Open Source"
      }
    ],
    preview: "/assets/connxtUI.png"
  },
  {
    title: 'Connxt',
    emoji: '🌐',
    desc: 'Connxt is a fullstack web framework currently in development. It is a batteries optional framework which provides the basic building blocks for a web application with different client and server side language options.',
    subdesc: `Developers can develop using a variety of backend languages including JS/TS, Go, Java, Python, and more. In addition to backend language choice, Connxt will also support different frontend frameworks including Vanilla HTML/CSS/JS, React, Svelte, Angular, Vue, and more.`,
    tags: [
      {
        id: 1,
        color: 'var(--red-800)',
        text: "In Development"
      },
      {
        id: 2,
        color: 'var(--grey-700)',
        text: "Closed Source"
      }
    ],
    preview: "/assets/connxt.png"
  },
  {
    title: 'ConnxtScaffold',
    emoji: '🛠️',
    desc: 'ConnxtScaffold is a command line interface that allows developers to scaffold projects easily from a variety of templates, including React, Svelte, Angular, Vue, Vanilla HTML/CSS/JS, initializing ConnxtUI, and more.',
    subdesc: `Provides building blocks for many different types of projects and applications, improving the development experience by minimizing bloat code, and the complexity of starting and maintaining the structure of a project or application.`,
    tags: [
      {
        id: 1,
        color: 'var(--green-700)',
        text: "Open Source"
      },
    ],
    preview: "/assets/connxtScaffold.png"
  },
  {
    title: 'Chrome Dino Game',
    emoji: '🦖',
    desc: 'A react Chrome Dino Game built using Phaser.js allowing the developer to easily add some customizable fun to their application, website, or 404 page. Includes built in sprite options, and allows for customizing everything.',
    subdesc: `Developers can simply install the component, add their sprites and backgrounds as props or select from the built in options, customize the speed and gravity if they wish, and thats it! It's an easy way to spruce up their site or application.`,
    tags: [
      {
        id: 1,
        color: 'var(--green-700)',
        text: "Open Source"
      },
    ],
    preview: "/assets/dino.png"
  },
];