import React, { useEffect, useState } from 'react';
import styles from './Textarea.module.css';

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    variant: "material" | "normal";
    placeholder?: string;
    borderRadius?: 'soft' | 'hard';
    materialLabelBackground?: string;
    outlineColor?: string;
    outlineColorActive?: string;
    backgroundColor?: string;
    textColor?: string;
};

const Textarea: React.FC<TextareaProps> = ({
    variant = "normal",
    placeholder,
    borderRadius = 'soft',
    materialLabelBackground = '#ffffff',
    outlineColor = '#000000',
    outlineColorActive = '#000000',
    backgroundColor = 'transparent',
    textColor = '#000000',
    ...props
}) => {
    const [hasValue, setHasValue] = useState(false);

    useEffect(() => {
        setHasValue(!!props.value);
    }, [props.value]);

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        setHasValue(e.target.value.length > 0);
    };

    const isMaterial = variant === 'material';

    const inlineStyles = {
        '--material-label-background': materialLabelBackground,
        '--outline-color': outlineColor,
        '--outline-color-active': outlineColorActive,
        '--background-color': backgroundColor,
        '--text-color': textColor
    } as React.CSSProperties;

    return (
        <div 
            className={styles.textareaWrapper} 
            style={inlineStyles}
        >
            <textarea
                className={`${isMaterial ? styles.textareaField : styles.textarea} ${styles[borderRadius]}`}
                placeholder={isMaterial ? "" : placeholder}
                onBlur={handleBlur}
                onChange={handleBlur}
                {...props}
            />
            {isMaterial && (
                <label 
                    htmlFor={props.id}
                    className={`${styles.placeholderText} ${hasValue ? styles.hasValue : ''}`}
                    id={`placeholder-${props.id}`}
                >
                    <div className={styles.labelText}>
                        {placeholder}
                    </div>
                </label>
            )}
        </div>
    );
};

export default Textarea;