import React from "react";
import styles from './MainPage.module.css'

import NewNavbar from "../../components/ui/NewNavbar/NewNavbar";
import TitleSection from "../../components/section/TitleSection/TitleSection";
import AboutSection from "../../components/section/AboutSection/AboutSection";
import SkillsSection from "../../components/section/SkillsSection/SkillsSection";
import EducationExperienceSection from "../../components/section/EducationExperienceSection/EducationExperienceSection";
import ProjectsSection from "../../components/section/ProjectsSection/ProjectsSection";
import LearningSection from "../../components/section/LearningSection/LearningSection";
import ContactSection from "../../components/section/ContactSection/ContactSection";
import Footer from "../../components/ui/Footer/Footer";

const MainPage: React.FC = () => {
    return (
        <div className={styles.page}>
            <NewNavbar/> 
            {/* TODO: 
                Finish New Navbar if I can, if not revert to old below
                    - Add vertical version for socials with animated tooltip
                    - Add name logo somehow
                    - Add responsiveness */} 
            <div className={styles.mainPage}>
                <TitleSection/>
                <div
                    style={{
                        width: "100%",
                        marginTop: "-10rem",
                    }}
                >
                    <div id="skills">
                        <SkillsSection/>
                    </div>
                    <div id="about">
                        <AboutSection/>
                    </div>
                    <div id="experience">
                        <EducationExperienceSection/>
                    </div>
                    <div id="projects">
                        <ProjectsSection/>
                    </div>
                    <div id="professional">
                        <LearningSection/>
                    </div>
                    <div id="contact">
                        <ContactSection/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default MainPage;