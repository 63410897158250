import React, { useState } from "react";
import styles from "./ProjectsSection.module.css";
import { myProjects } from '../../../constants/index';

const ProjectsSection: React.FC = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const currentProject = myProjects[selectedIndex];

    const handleNavigation = (direction: string) => {
        setSelectedIndex((prevIndex) => {
            if (direction === 'prev') {
                return prevIndex === 0 ? myProjects.length - 1 : prevIndex - 1;
            } else {
                return prevIndex === myProjects.length - 1 ? 0 : prevIndex + 1;
            }
        })
    }

    return (
        <section className={styles.section}>
            <h2>My Work</h2>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <div className={styles.textContainer}>
                        <div className={styles.top}>
                            <div className={styles.emoji}>
                                <div>{currentProject.emoji}</div>
                            </div>
                            <div className={styles.tags}>
                                {currentProject.tags.map(tag => (
                                    <span key={tag.id} className={styles.tag} style={{backgroundColor: tag.color}}>{tag.text}</span>
                                ))}
                            </div>
                        </div>
                        <p className={styles.title}>{currentProject.title}</p>
                        <p>{currentProject.desc}</p>
                        <p>{currentProject.subdesc}</p>
                    </div>
                    <div className={styles.navContainer}>
                        <button onClick={() => handleNavigation('prev')}>
                            <span className="material-symbols-rounded">arrow_back</span>
                        </button>
                        <button onClick={() => handleNavigation('next')}>
                            <span className="material-symbols-rounded">arrow_forward</span>
                        </button>
                    </div>
                </div>
                <div className={styles.right}>
                    <img src={currentProject.preview} alt="Preview" className={styles.preview} />
                </div>
            </div>
        </section>
    );
};

export default ProjectsSection;