import React from "react";
import styles from "./LearningSection.module.css";

const LearningSection: React.FC = () => {
    return (
        <section className={styles.section}>
            <h2>Professional Persona</h2>
            <div className={styles.grid}>
                <div className={styles.card} style={{
                    gridColumn: "span 4",
                    gridRow: "span 1",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">crowdsource</span>
                        </div>
                        <h3>Leaderlike</h3>
                    </div>
                    <p>Classmates, teammates, and colleagues have described me as leaderlike and taking initiative in groups and teams. Taking on a key role for the success of the group and organizing with third parties.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 2",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">lightbulb</span>
                        </div>
                        <h3>Idea Generator</h3>
                    </div>
                    <p>Peers have accessed me to being an idea generator, both for work within the team, and outside of that. Whether creatively or analytically, my ideas have proved unique and helpful to success.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 3",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">extension</span>
                        </div>
                        <h3>Problem Solver</h3>
                    </div>
                    <p>When I see a problem I want to solve it and figure out all of the intricacies of the solution. My team members have agreed and noted that as one of my best professional traits in both contributing to the team and getting to the expected results.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 1",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">event</span>
                        </div>
                        <h3>Scheduled</h3>
                    </div>
                    <p>I tend to set a schedule and stick to it, while still maintaining adaptability to the needs of others. This trait allows me to work well with a diverse team with different personal time requirements.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 2",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">priority_high</span>
                        </div>
                        <h3>Assertive</h3>
                    </div>
                    <p>My colleagues and team members have described me as being assertive with my ideas and taking initiative. This has helped them feel comfortable also sharing their ideas allowing for open discussions and better results.</p>
                </div>
                <div className={styles.card} style={{
                    gridColumn: "span 2",
                    gridRow: "span 2",
                }}>
                    <div className={styles.top}>
                        <div className={styles.emoji}>
                            <span className="material-symbols-rounded">account_tree</span>
                        </div>
                        <h3>Egalitarian</h3>
                    </div>
                    <p>Tests show that I prefer a flatter, egalitarian leadership structure. Giving everyone a voice on the team and not having a single person making all of the decisions for the team.</p>
                </div>
            </div>
        </section>
    )
}

export default LearningSection;