import React, { useState } from "react";
import styles from "./SpotlightCard.module.css";

type SpotlightCardProps = React.HTMLAttributes<HTMLDivElement> & {
    borderColor: string;
    spotlightColor: string;
    logoColor?: string;
    logo?: React.ReactElement | string;
};

const SpotlightCard: React.FC<SpotlightCardProps> = ({
    children,
    borderColor,
    spotlightColor,
    logoColor,
    logo
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        setPosition({ x: e.clientX - left, y: e.clientY - top });
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div 
            className={styles.spotlightCard}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                borderColor: isHovered ? borderColor : "",
                background: isHovered
                  ? `radial-gradient(circle at ${position.x}px ${position.y}px, ${spotlightColor}, transparent 50%)`
                  : "transparent",
              }}
        >
            {typeof logo === "string" || logo === undefined ? (
                <p>{logo}</p>
            ) : (
                React.cloneElement(logo, { color: isHovered ? logoColor : "var(--text-main)" })
            )}
            {children}
        </div>
    );
};

export default SpotlightCard;

type CardData = {
    logo: JSX.Element | string;
    text: string;
    borderColor: string;
    spotlightColor: string;
    logoColor: string;
};

type SpotlightCardGroupProps = {
    cardData: CardData[];
};

export const SpotlightCardGroup: React.FC<SpotlightCardGroupProps> = ({ cardData }) => {
    return (
        <div
            className={styles.cardGroup}
        >
            {cardData.map((card, index) => (
                <SpotlightCard
                    borderColor={card.borderColor}
                    spotlightColor={card.spotlightColor}
                    logoColor={card.logoColor}
                    key={index}
                    logo={card.logo}
                >
                    <p>{card.text}</p>
                </SpotlightCard>
            ))}
        </div>
    );
};